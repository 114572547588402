<i18n locale="th" lang="yaml" src="@i18n/service/onfield-service.th.yaml"></i18n>
<i18n locale="th" lang="yaml" >
page.title : "สร้างรายการเคลม"
page.description : "ขั้นตอนที่ 5/8"

onfield_claim.action.update.confirm.title : "กรุณายืนยันเพื่อดำเนินการต่อไป"
onfield_claim.action.update.confirm.message : "ต้องการดำเนินการไปยังขั้นตอนถัดไปหรือไม่?"
onfield_claim.action.update.confirm.success : "ดำเนินการเรียบร้อย"
onfield_claim.action.update.no_change : "ไม่ได้มีการแก้ไขค่า"
onfield_claim.detail.no_detail: "ไม่มีการบันทึกในระบบ"

action.ask_leave.title : "ยืนยันออกจากหน้าจอนี้"
action.ask_leave.message : "คุณได้แก้ไขข้อมูลอะไหล่ และ ยังไม่ได้บันทึกเข้าระบบ, ยืนยันต้องการยกเลิกการแก้ไขนี้ ?"

</i18n>
<template>
	<div class="page-padding">
		<my-page-header class="main-header" :title="$t('page.title')" :description="$t('page.description')" />
		<div class="background">
			<ClaimPartsOnfield
				ref="claimPartsRef"
				:service="service"
				:can-update="true"
				@claim="handleClaimCheck"/>
			<div class="page-action-right next-button">
				<a-button
					type="primary"
					icon="right-circle"
					@click="handleSave">
					{{$t('action.next.page')}}
				</a-button>
			</div>
		</div>

	</div>
</template>

<script>
import PageMixin from "@mixins/PageMixin.vue"
import axios from "axios"
import ModelError from "@utils/errors/ModelError"
import _isEqual from "lodash/isEqual"
import ClaimPartsOnfield from "@components/service/claim/ClaimPartsOnfield.vue"
import {CLAIM_STATUS} from "@utils/serviceUtil"
import {RMHTMLREGEX, convertStr} from "@utils/stringUtil"

export default {
	components : {
		ClaimPartsOnfield
	} ,
	mixins : [PageMixin] ,
	props : {
		otherDetails : {
			type : null,
			default : () => []
		} ,
	} ,
	data() {
		return {
			service: {},
			loading : false,
			currentServiceId : undefined,
			claimRequest : {} ,
			serviceParts : {},
			ignorePreventExit : false,
			oldFormData : {} ,
			claimChecks: {},
			jobRepair : {} ,
			saveError : undefined,
			isClaimDrafted : false,
		}
	} ,
	computed : {
		componentError() {
			return this.saveError instanceof ModelError ? this.saveError : undefined
		},
		isClaimChecked() {
			for (const id in this.claimChecks) {
				if(this.claimChecks[id]) return true
			}
			return false
		},
	} ,
	watch : {
		$route() {
			this.fetchJobs()
		}
	} ,
	mounted() {
		this.fetchService()
		this.fetchData()
	} ,
	beforeMount() {
		window.addEventListener("beforeunload",this.preventExit)
	} ,
	beforeDestroy() {
		window.removeEventListener("beforeunload",this.preventExit)
	} ,
	methods : {
		handleClaimCheck(value) {
			this.claimChecks = {...this.claimChecks, ...value}
		},
		async fetchData() {
			const serviceId = this.$route.params.id
			if (!serviceId) {
				return
			}
			this.ignorePreventExit = true
			this.loading = true
			try {
				const jobResponse = await axios.get(`/api/services/${serviceId}/jobs`)
				const claimResponse = await axios.get(`/api/services/${serviceId}/claim-request`)
				const jobData = jobResponse?.data?.data || {}
				const claimData = claimResponse?.data?.data || {}
				this.jobRepair = ('job_repair' in jobData) ? jobData.job_repair : {}
				this.claimRequest = ('claim_request' in claimData) ? claimData.claim_request : {}
				this.serviceParts = ('service_parts' in claimData) ? claimData.service_parts : {}
				this.isClaimDrafted = this.claimRequest.updated_datetime
				this.initClaimRequest()
			} catch (error) {
				this.fetchError(error)
			} finally {
				this.loading = false
				this.ignorePreventExit = false
			}
		} ,
		initClaimRequest() {
			this.saveError = undefined
			this.$nextTick(() => {
				this.claimRequest.status = CLAIM_STATUS.STATUS_DRAFT
				this.$refs.claimPartsRef.setData(this.claimRequest,this.serviceParts)
				this.oldFormData = this.dumpFormData()
				if(this.serviceParts?.repair?.[0]){
					for (const servicePartRepair of this.serviceParts?.repair) {
							this.claimChecks[servicePartRepair.id] = !!(servicePartRepair.num_request && servicePartRepair.num_request > 0)
					}
				}
			})
		} ,
		fetchService() {
			const serviceId = this.$route.params.id
			if (!serviceId) {
				return
			}
			this.showPageLoading(true)
			axios.get(`/api/services/${serviceId}`).then((response)=>{
				this.service = response.data.data.service
				this.repair = response.data.data.repair
			}).catch((error)=>{
				this.fetchError(error)
			}).finally(()=>{
				this.hidePageLoading()
			})
		} ,
		dumpFormData() {
			const eventDetails = this.jobRepair.event_detail_rich
			const details = eventDetails ? convertStr(eventDetails, RMHTMLREGEX, '') : this.$t('onfield_claim.detail.no_detail')
			const formData = {
				claim_request : {
					id : this.claimRequest.id,
					detail : details,
				},
				claim_parts : this.$refs.claimPartsRef.getData()
			}
			return formData
		} ,
		handleSave() {
			const formData = this.dumpFormData()
			if (this.canUpdatePaymentPart && !this.validatePaymentData(formData)) {
				return
			}
			const isValid = this.validateClaim(formData)
			if(!isValid) return

			this.$confirm({
				title : this.$t('onfield_claim.action.update.confirm.title') ,
				content : this.$t('onfield_claim.action.update.confirm.message') ,
				okText : this.$t('common.ok') ,
				cancelText : this.$t('common.cancel'),
				maskClosable : true,
				onOk: () => this.saveData(formData),
			})
		} ,
		validateClaim(formData) {
			let isValid = true
			for (const claimPart of formData.claim_parts) {
				const isClaimChecked = this.claimChecks[claimPart.id]
				if(!isClaimChecked) continue

				if(claimPart.num_request === null || claimPart.num_request === 0){
					this.$message.info(this.$t('onfield_claim.action.update.no_change'))
					isValid = false
					break
				}
			}
			return isValid
		},
		async saveData(formData) {
			this.showPageLoading(true)
			try {
				if (this.isClaimDrafted) {
					await axios.put(`/api/services/${this.service.id}/claim-request`, formData)
				} else if (!this.isClaimDrafted && this.isClaimChecked) {
					formData.next_status = CLAIM_STATUS.STATUS_DRAFT
					await axios.post(`/api/services/${this.service.id}/claim-request/new`)
					await axios.post(`/api/services/${this.service.id}/claim-request/update-status`,formData)
				}
				this.$emit('error',undefined)
			} catch (error) {
				this.$emit('error',error)
			}

			try {
				if(this.isClaimChecked) await axios.put(`/api/services/${this.service.id}/claim-request`, formData)
				await axios.post(`/api/services/${this.service.id}/update-onfield-state`, { state: 'cost_estimate' })

				this.ignorePreventExit = true
				this.$message.success(this.$t('onfield_claim.action.update.confirm.success',{name:this.service.service_no}))
				this.$router.push({ name: 'onfield/cost_estimate', params:{id:this.service.id} })
			} catch (error) {
				this.saveError = error
			} finally {
				this.hidePageLoading()
			}
		},
		preventExit(event) {
			if (this.checkPreventExit()) {
				event.preventDefault()
				event.returnValue = ""
			}
		},
		isDataChange(formData) {
			const change = !_isEqual(this.oldFormData,formData)
			return change
		} ,
		checkPreventExit(formData=null) {
			if (this.ignorePreventExit)
				return false
			if(!formData) {
				formData = this.dumpFormData()
			}
			return this.isDataChange(formData)
		} ,
	} ,
	beforeRouteLeave(routeTo,routeFrom,next) {
		const formData = this.dumpFormData()
		if (this.checkPreventExit(formData)) {
			this.$confirm({
				title : this.$t('action.ask_leave.title') ,
				content : this.$t('action.ask_leave.message') ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk: ()=> {
					next()
				} ,
			})
		} else {
			next()
		}
	}
}
</script>

<style lang="less" scoped>
.main-header {
	text-align: center;
}
.background {
	background : white;
	padding: 10px 10px 50px;
	.mobile & {
		padding : 12px 16px;
	}
}
.page-action-right {
	float: right;
	margin-bottom : 8px;
}
</style>
